<template>
  <v-row :class="{ 'd-inline-block mx-0 flex-grow-0': !thumbnails }">
    <div v-if="thumbnails" class="d-flex flex-wrap flex">
      <v-col
        v-if="uploadable"
        :cols="buttonWidth || buttonHeight ? 12 : cols"
        class="d-flex justify-center align-center"
      >
        <div
          class="box"
          :style="{
            height: buttonHeight ? buttonHeight + 'px' : '100%',
            paddingTop: buttonHeight ? buttonHeight : '100%',
          }"
        >
          <media-upload
            :is-android="isAndroid"
            :thumbnail="true"
            :button-height="buttonHeight"
            :disabled="disabled"
            :uploading.sync="uploading"
            :button-text="buttonText"
            :on-change="imageUploaded"
          />
        </div>
      </v-col>
      <v-col v-for="img in images" :key="img" :cols="cols">
        <div
          v-if="img !== 'loading'"
          class="box"
          :style="{
            height: buttonHeight ? buttonHeight + 'px' : '100%',
            paddingTop: buttonHeight ? buttonHeight : '100%',
          }"
        >
          <ImageView
            :readonly="readonly"
            :on-remove="removeImg"
            class="shover-image-view"
            :src="img"
            small-width="100%"
            @click="imageClicked"
          />
        </div>
        <div v-else class="box">
          <v-skeleton-loader width="100%" height="100%" type="image" />
        </div>
      </v-col>
    </div>
    <v-icon v-else :color="iconColor" @click.stop="imageClicked"
      >{{ icon }}
    </v-icon>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      content-class="rounded-xl"
      class="white"
      :width="zoomIn ? 1600 : 500"
      @click:outside="currImage = null"
    >
      <v-sheet class="rounded-xl overflow-auto">
        <v-carousel v-model="currImage" class="" height="auto">
          <v-carousel-item
            v-for="slide in images"
            :key="slide"
            class="pb-10"
            :value="slide"
          >
            <video
              v-if="isVideo(slide) && currImage === slide"
              width="100%"
              autoplay
              controls
              height="500"
              :src="slide"
            />
            <v-img
              v-else
              :class="{ 'image-zoom': zoomIn }"
              class="zoom-hover"
              contain
              :src="slide"
              @click="zoom"
            >
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-sheet>
    </v-dialog>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions'
import ImageView from 'components/common/image-view'
import MediaUpload from 'components/common/media-upload'
import { mapGetters } from 'vuex'

export default {
  name: 'Gallery',
  components: { MediaUpload, ImageView },
  mixins: [CommonFunctions],
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Upload',
    },
    buttonWidth: {
      type: [String, Number],
    },
    buttonHeight: {
      type: [String, Number],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    thumbnails: {
      type: Boolean,
      default: false,
    },
    uploadable: {
      type: Boolean,
      default: false,
    },
    rotateImage: {
      type: Function,
      default: () => ({}),
    },
    removeImg: {
      type: Function,
      default: () => ({}),
    },
    icon: {
      type: String,
    },
    listingId: {
      type: Number,
    },
    iconColor: {
      type: String,
    },
    cols: {
      type: Number,
      default: 6,
    },
    onChange: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      hovered: false,
      zoomIn: false,
      uploading: false,
      dialog: false,
      currImage: '',
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    imagesLength() {
      return this.images && this.images.length
    },
    getDeviceType() {
      const userAgent = window.navigator.userAgent
      if (/Android/i.test(userAgent)) {
        return 'Android'
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return 'iPhone'
      } else {
        return 'Unknown'
      }
    },
    isAndroid() {
      return this.getDeviceType === 'Android'
    },
  },
  watch: {
    uploading(val) {
      if (val) {
        this.$emit('upload-started')
      }
    },
    imagesLength(value) {
      if (!value) {
        this.dialog = false
      }
    },
  },
  methods: {
    imageClicked(img) {
      this.currImage = img
      this.dialog = true
    },
    hoverPic() {
      this.hovered = true
    },
    zoom() {
      this.zoomIn = !this.zoomIn
    },
    outPic() {
      this.hovered = false
    },
    remove(link) {
      this.removeImg(link)
    },
    imageUploaded(images) {
      this.onChange(images)
    },
  },
}
</script>

<style lang="scss" scoped>
.hover-image-view:hover {
  cursor: pointer;
}

.zoom-hover:hover {
  cursor: zoom-in;
}

.zoom-hover.image-zoom:hover {
  cursor: zoom-out;
}

.box {
  width: 100%;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>
