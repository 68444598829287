<template>
  <v-stepper-content :step="step" class="pa-0 fill-height">
    <v-container fluid class="pa-0">
      <div class="text-h6 font-weight-medium">{{ $t('ContactInfo') }}</div>
      <v-form ref="form" class="flex d-flex flex-column">
        <v-row no-gutters class="my-4">
          <v-col cols="12" sm="6" class="pr-sm-6">
            <v-text-field
              v-model="clientInfo.email"
              prepend-inner-icon="person"
              :rules="emailRules"
              outlined
              name="email"
              required
              label="Email"
              type="text"
            />
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-6">
            <v-text-field
              v-model="clientInfo.phone_number"
              prepend-inner-icon="phone"
              outlined
              name="Phone"
              label="Phone"
              :rules="phoneRules"
              type="text"
            />
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-6">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              required
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="clientInfo.birthday"
                  persistent-hint
                  outlined
                  v-bind="attrs"
                  label="Date of birth"
                  :rules="[required]"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="clientInfo.birthday"
                :max="maxDate"
                min="1940-01-01"
                @change="save"
              />
            </v-menu>
          </v-col>
          <v-col v-if="!isResort" cols="12">
            <v-checkbox
              v-model="requestRemember"
              :label="$t('I want to be a member')"
              :hint="
                user.tenant_id === 1
                  ? $t('Membership gives you discounts on future reservations!')
                  : ''
              "
              persistent-hint
            />
          </v-col>
        </v-row>
        <div>
          <v-row class="align-center">
            <v-col cols="12">
              <div class="text-h6 font-weight-medium my-3">
                {{ $t('Upload ID') }}
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center" height="280px">
            <v-col cols="12" sm="12" md="8">
              <profile-upload
                :title="$t('Selfie with your ID')"
                :value.sync="documentId"
              >
                <template #instructions>
                  <v-img
                    class="mx-4 flex"
                    src="@/assets/images/selfie.png"
                    alt="Description"
                    contain
                    max-width="400"
                    min-width="300"
                  />
                </template>
              </profile-upload>
            </v-col>
          </v-row>
          <div
            v-if="showUploadError && !documentId"
            class="error--text text-caption"
          >
            {{ $t('You must upload ID document') }}
          </div>
          <div class="text-caption flex red-health mt-1">
            {{ $t('DocumentationRequirement') }}
          </div>
        </div>
        <v-row
          v-if="Object.keys(preCheckIn.additional_docs || []).length"
          class="justify-center"
        >
          <v-col cols="12">
            <div
              v-if="Object.keys(preCheckIn.additional_docs).length"
              class="text-h6 font-weight-medium mt-6"
            >
              {{ $t('Additional documents') }}
            </div>
          </v-col>
          <v-col cols="12">
            <div class="text-h6 font-weight-medium my-1">
              {{ preCheckIn.additional_docs.name }}
            </div>
            <div class="text-caption flex red-health mt-1">
              {{ preCheckIn.additional_docs.description }}
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <profile-upload
              :title="$t('Upload file')"
              :value.sync="additionalDocumentId"
            >
              <template #instructions>
                <v-img
                  class="mx-4 flex"
                  src="@/assets/images/b2slip.png"
                  alt="Description"
                  contain
                  max-width="400"
                  min-width="300"
                />
              </template>
            </profile-upload>
          </v-col>
        </v-row>
      </v-form>

      <v-divider class="my-4" />
      <div class="text-h6 font-weight-medium">{{ $t('Policy') }}</div>
      <div class="text-caption">{{ $t('PleaseReadTerms') }}</div>
      <ReadTermsBtn :text="preCheckIn.generated_sign_doc">
        <template #activator="slotData">
          <v-btn class="white--text my-3" color="darkpurle" v-on="slotData.on">
            {{ $t('Read Terms') }}
          </v-btn>
        </template>
      </ReadTermsBtn>
    </v-container>
    <div class="flex d-flex justify-center align-end">
      <div>
        {{
          $t(
            'By submitting , you agree that you have reviewed and agreed to the'
          )
        }}

        <ReadTermsBtn :text="preCheckIn.generated_sign_doc">
          <template v-slot:activator="slotData">
            <a
              class="px-1 black--text text-decoration-underline font-weight-medium"
              v-on="slotData.on"
              >{{ $t('Terms') }}</a
            >
          </template>
        </ReadTermsBtn>
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
import moment from 'moment'
import formRules from '@/components/mixins/form-rules-mixin'
import ReadTermsBtn from '@/components/common/read-terms-btn'
import ProfileUpload from '@/components/common/profile-upload'
import DeviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'PersonalInfo',
  components: { ProfileUpload, ReadTermsBtn },
  mixins: [formRules, DeviceMixin],
  props: ['preCheckIn', 'isResort', 'step'],
  data() {
    return {
      clientInfo: this.preCheckIn.client_info,
      dateMenu: false,
      documentId: this.preCheckIn.id_pic,
      additionalDocumentId: this.preCheckIn.additional_doc,
      showUploadError: false,
      requestRemember: this.preCheckIn.request_to_memeber,
    }
  },
  computed: {
    maxDate() {
      return moment().subtract(18, 'years').format('YYYY-MM-DD')
    },
    user() {
      return this.$store.getters.user
    },
  },
  watch: {
    dateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  created() {
    this.clientInfo.email = this.preCheckIn.client_info.email || this.user.email
    this.clientInfo.birthday =
      this.preCheckIn.client_info.birthday || this.user.birthday
    this.clientInfo.phone_number =
      this.preCheckIn.client_info.phone_number || this.user.phone
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    validate() {
      this.showUploadError = !this.documentId
      if (this.$refs.form.validate() && this.documentId) {
        const payload = {
          client_info: this.clientInfo,
          request_to_memeber: this.requestRemember,
          vr_licence_signed: true,
          id_pic: this.documentId,
          additional_doc: this.additionalDocumentId,
        }
        this.$emit('step-forward', payload)
      }
    },
  },
}
</script>

<style scoped>
.v-stepper__content >>> .v-stepper__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashed-border {
  border-style: dashed;
}
.text-box,
.image-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contained-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>
