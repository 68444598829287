<template>
  <v-stepper-content :step="step" class="pa-0">
    <v-container fluid>
      <div class="text-h6 font-weight-medium">{{ $t('Special Requests') }}</div>
      <v-row class="align-center flex-column">
        <v-col v-for="(request, idx) in allowedRequests" :key="idx">
          <AddRemoveItem
            :label="$t(request.key)"
            :value.sync="request.amount"
            :max="1"
          />
        </v-col>
      </v-row>
      <div
        v-if="
          preCheckIn.has_assigned_parking ||
          preCheckIn.has_parking_pass ||
          preCheckIn.listing_num_of_rooms >= 3
        "
      >
        <v-divider class="my-4" />
        <div class="text-h6 font-weight-medium">Do you need parking place?</div>
        <v-radio-group v-model="clientInfo.need_parking" row>
          <v-radio :label="$t('Yes')" :value="true" />
          <v-radio :label="$t('No')" :value="false" />
        </v-radio-group>
        <span
          v-if="preCheckIn.has_parking_pass && clientInfo.need_parking"
          class="warning--text my-2"
        >
          A parking pass will be available for purchase from our store.
        </span>
        <div v-if="clientInfo.need_parking">
          <v-form
            v-if="
              preCheckIn.has_assigned_parking || preCheckIn.has_parking_pass
            "
            ref="form"
            class="flex d-flex"
          >
            <v-row no-gutters>
              <v-col cols="12" class="grey--text pb-3">
                If you don't have the details yet you would be able to update
                this form again on your arrival date
              </v-col>
              <v-col cols="12" sm="6" md="3" class="pr-3">
                <v-text-field
                  v-model="clientInfo.car_model"
                  outlined
                  :label="$t('CarModel')"
                  prepend-inner-icon="directions_car"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" class="pr-3">
                <v-text-field
                  v-model="clientInfo.licence_plate"
                  outlined
                  :label="$t('LicencePlate')"
                  prepend-inner-icon="straighten"
                />
              </v-col>
            </v-row>
          </v-form>
          <AddRemoveItem
            v-else
            label="How many cars?"
            :value.sync="clientInfo.car_amount"
            :min="1"
            :max="3"
            :description="carAmountWarn"
          />
        </div>
      </div>
    </v-container>
  </v-stepper-content>
</template>

<script>
import AddRemoveItem from '@/components/common/AddRemoveItem'
import formRules from '@/components/mixins/form-rules-mixin'

export default {
  name: 'SpecialRequests',
  components: { AddRemoveItem },
  mixins: [formRules],
  props: ['preCheckIn', 'step'],
  data() {
    return {
      specialRequest: [],
      need_parking: false,
      clientInfo: this.preCheckIn.client_info,
    }
  },
  computed: {
    allowedRequests() {
      return this.specialRequest.filter(req => this.isAllowedReq(req.key))
    },
    carAmountWarn() {
      if (
        this.clientInfo.car_amount &&
        this.clientInfo.car_amount > this.preCheckIn.listing_num_of_rooms - 2
      ) {
        return `This listing is best suited for ${
          this.preCheckIn.listing_num_of_rooms - 2
        } cars, please advise with us through the booking channel if that is an issues.`
      }
      return ''
    },
  },
  mounted() {
    Object.entries(this.preCheckIn.special_requests).forEach(([key]) => {
      const request = { amount: 0, key }
      if (!isNaN(key.charAt(0))) {
        request.amount = parseInt(key.charAt(0))
        request.key = key.split(' X ')[1]
      }
      this.specialRequest.push(request)
    })
  },
  methods: {
    validate() {
      if (
        this.clientInfo.need_parking &&
        (this.preCheckIn.has_assigned_parking ||
          this.preCheckIn.has_parking_pass) &&
        !this.$refs.form.validate()
      ) {
        return
      }
      const special_requests = this.specialRequest.reduce(
        (requests, { key, amount }) => {
          if (amount >= 1) {
            requests[`${amount} X ${key}`] = true
            return requests
          } else {
            requests[key] = false
          }
          return requests
        },
        {}
      )
      this.$emit('step-forward', { ...this.clientInfo, special_requests })
    },
    isAllowedReq(reqName) {
      if (!this.preCheckIn.allow_air_mattress && reqName.includes('Air')) {
        return false
      }
      if (reqName.includes('Toys')) {
        return false
      }
      return !reqName.includes('Long stay')
    },
  },
}
</script>

<style scoped></style>
