<template>
  <div>
    <div v-for="item in items" :key="item.id" class="d-flex my-2 pa-1">
      <v-avatar tile size="50" class="rounded">
        <img
          :src="formatImageUrl(item.item_image)"
          width="50"
          height="50"
          class="rounded"
          alt=""
        />
      </v-avatar>
      <div class="flex d-flex justify-space-between px-3 flex-column">
        <div>
          <div class="text-subtitle-1 text--black text-capitalize">
            {{ item.item_name }}
            <span
              v-if="formatStatus(item.status)"
              class="warning--text text-caption"
              >{{ formatStatus(item.status) }}</span
            >
          </div>
          <div class="text-caption text--secondary text-pre-line">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions'

export default {
  name: 'PurchasesReview',
  mixins: [CommonFunctions],
  props: ['items'],
  methods: {
    formatStatus(status) {
      if (status === 'pending_capture') {
        return 'pending approval'
      }
      return null
    },
  },
}
</script>

<style scoped></style>
