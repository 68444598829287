<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    persistent
    :fullscreen="isMobile"
  >
    <template v-slot:activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card class="d-flex flex-column">
      <v-card-title
        class="justify-center align-center pa-6 overflow-y-auto dialog-title"
      >
        <span>Our Terms</span>
        <v-icon class="" @click="dialog = false"> close </v-icon>
      </v-card-title>
      <v-card-text class="px-10 px-sm-20">
        <div v-html="text" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deviceMixin from '@/components/mixins/deviceMixin'

export default {
  name: 'ReadTermsBtn',
  mixins: [deviceMixin],
  props: ['text'],
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style scoped>
.dialog-title {
  position: relative;
}

.v-icon {
  position: absolute;
  right: 20px;
  top: 30px;
}
</style>
