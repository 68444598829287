<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    :fullscreen="isMobile"
    class="rounded-xl"
    @click:outside="close"
  >
    <template #activator="{ on }">
      <div class="text-caption secondary--text">
        {{ itemDescription }}
      </div>
      <v-btn
        color="primary"
        elevation="0"
        :disabled="isDisabled"
        block
        class="rounded text-subtitle-2 darken-1 font-weight-bold white--text"
        v-on="on"
        @click="itemClicked"
      >
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card v-if="!requestSent">
      <v-avatar tile size="80" class="rounded ml-6 mt-16">
        <img :src="formatImageUrl(storeItem.image)" alt="" />
      </v-avatar>
      <div class="text-sm-h6 ml-6 my-3 font-weight-bold">
        {{ storeItem.name }}
      </div>
      <v-divider class="ml-6" />
      <v-btn
        style="z-index: 10"
        icon
        light
        :fixed="isMobile"
        :absolute="!isMobile"
        top
        left
        @click="dialog = false"
      >
        <v-icon color="grey darken-2"> fas fa-arrow-left </v-icon>
      </v-btn>
      <v-card-text class="black--text card-container">
        <v-form v-if="hasSpecifications" ref="form" class="pt-3">
          <div class="text-subtitle-1 font-weight-medium">
            Choose {{ storeItem.name }}
          </div>
          <v-select
            v-model="menuItem"
            :items="menuOptions"
            dense
            color="primary darken-1"
            :rules="[required]"
            outlined
            @change="calcPrice"
          >
            <template #selection="{ item }">
              <div>
                {{ item.text }}
              </div>
            </template>
            <template #item="{ item }">
              <div class="d-flex align-center my-1">
                <v-img
                  class="rounded-xl"
                  height="40"
                  width="40"
                  :src="formatImageUrl(item.value.main_image)"
                />
                <div class="flex pa-2">
                  {{ item.text }}
                </div>
              </div>
            </template>
          </v-select>
          <div v-if="menuItem" class="py-4">
            <v-carousel
              cycle
              hide-delimiters
              dark
              show-arrows-on-hover
              prev-icon="fas fa-angle-left"
              next-icon="fas fa-angle-right"
              class="rounded-lg text-caption"
              height="auto"
            >
              <v-carousel-item
                v-for="(slide, i) in menuItem.images"
                :key="i"
                class="gallery-container"
              >
                <v-img :src="formatImageUrl(slide)" aspect-ratio="1.5" />
              </v-carousel-item>
            </v-carousel>
          </div>
          <div v-else class="py-4">
            <v-img
              class="rounded-lg"
              :src="formatImageUrl(storeItem.media[0])"
              aspect-ratio="2.1"
            />
          </div>

          <div v-if="menuItem" class="pt-3">
            <div class="text-subtitle-1">Description</div>
            <div class="text-caption">{{ menuItem.description }}</div>
          </div>
          <div v-if="menuItem" class="pt-3">
            <div class="text-subtitle-1">What's included?</div>
            <div class="text-caption d-flex">
              <v-row no-gutters class="flex-wrap">
                <v-col v-for="i in menuItem.bullets" :key="i" cols="12" sm="6">
                  <li class="text-capitalize">
                    {{ i }}
                  </li>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="menuItem && menuItem.disclaimer" class="pt-3">
            <div class="text-subtitle-1">Disclaimer</div>
            <div class="text-caption">
              {{ menuItem.disclaimer }}
            </div>
          </div>
          <v-row v-if="menuItem">
            <v-col
              v-for="option in menuItem.menu_fields"
              :key="option.key"
              cols="12"
              sm="6"
            >
              <div
                v-if="option.units === 'datetime' && option.type === 'select'"
              >
                <div class="text-subtitle-1 font-weight-medium">
                  {{ option.label }}
                </div>
                <v-select
                  v-model="fields[option.key]"
                  :rules="[required]"
                  outlined
                  dense
                  color="black"
                  :items="option.value"
                  @change="calcPrice"
                >
                  <template #selection="{ item }">
                    <span>{{ formatDate(item) }}</span>
                  </template>
                  <template #item="{ item }">
                    <span>{{ formatDate(item) }}</span>
                  </template>
                </v-select>
              </div>
              <div v-if="option.units === 'units' && option.type === 'select'">
                <div class="text-subtitle-1 font-weight-medium">
                  {{ option.label }}
                </div>
                <v-select
                  v-model="fields[option.key]"
                  :rules="[required]"
                  outlined
                  dense
                  color="black"
                  :items="option.value"
                  @change="calcPrice"
                />
              </div>
              <div v-if="option.units === 'date' && option.type === 'range'">
                <div class="text-subtitle-1 font-weight-medium">
                  {{ option.label }}
                </div>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="shouldCloseOnClick()"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="rangeText(fields[option.key])"
                      prepend-inner-icon="fas fa-calendar-alt"
                      persistent-hint
                      hint="Dates must be between check-in to check-out"
                      readonly
                      outlined
                      dense
                      :rules="rangeRules"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template #default>sdfsd</template></v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="fields[option.key]"
                    :min="reservationBasicInfo.check_in"
                    :max="reservationBasicInfo.check_out"
                    range
                    :rules="[required, rangeRules]"
                    @change="calcPrice"
                  />
                </v-menu>
              </div>
              <div v-if="option.units === 'date' && option.type === 'date'">
                <div class="text-subtitle-1 font-weight-medium">
                  {{ option.label }}
                </div>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="fields[option.key]"
                      prepend-inner-icon="fas fa-calendar-alt"
                      persistent-hint
                      hint="Dates must be between check-in to check-out"
                      readonly
                      outlined
                      :rules="[required]"
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="fields[option.key]"
                    :min="reservationBasicInfo.check_in"
                    :max="reservationBasicInfo.check_out"
                    @change="calcPrice"
                  />
                </v-menu>
              </div>
              <div v-if="option.units === 'time' && option.type === 'time'">
                <div class="text-subtitle-1 font-weight-medium">
                  {{ option.label }}
                </div>
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="fields[option.key]"
                      persistent-hint
                      readonly
                      outlined
                      :rules="[required]"
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-time-picker
                    v-model="fields[option.key]"
                    :allowed-minutes="[0, 30]"
                    @change="calcPrice"
                  />
                </v-menu>
              </div>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="fields.comments"
                label="Comments"
                outlined
                rows="4"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="flex-wrap pa-5">
        <div
          class="d-flex py-2 flex text-subtitle-1 font-weight-medium justify-space-between"
        >
          <div>Total</div>
          <div>${{ currentPrice }}</div>
        </div>
        <v-btn
          :loading="loading"
          color="primary"
          elevation="0"
          :disabled="isDisabled"
          block
          class="rounded text-subtitle-2 darken-1 font-weight-bold white--text"
          @click="createAuthorizationRequest"
        >
          {{
            hasAbility('create-store-purchase')
              ? 'Create Payment Request'
              : 'Reserve'
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      v-if="requestSent"
      class="d-flex pa-7 justify-center align-center"
      style="position: relative"
    >
      <div class="d-flex flex-column justify-center align-center flex">
        <img src="@/assets/images/request_sent.png" width="200" alt="" />
        <div class="text-h6 my-3">Request Sent!</div>
        <div class="text-caption my-3 px-7 px-sm-3">
          In this moment we are doing our best to provide you
          {{ menuItem && menuItem.name }}.
        </div>
        <div class="text-caption my-3 px-7 px-sm-3">
          One of our agents will get back to you in the next 24 hours.
        </div>
        <v-btn class="mt-3 px-10" color="primary" @click="close"> OK </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import isEmpty from 'lodash/fp/isEmpty'
import { DISABLED_STORE_ITEM_STATUSES } from '@/consts'
import formRules from 'components/mixins/form-rules-mixin'
import deviceMixin from 'components/mixins/deviceMixin'
import commonFunctions from 'components/mixins/common-functions'
import moment from 'moment'
import axios from 'axios'
import sort from 'lodash/fp/sortBy'
import { mapActions } from 'vuex'
import { mapState } from 'vuex'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'
export default {
  name: 'StoreItemExternalDialog',
  mixins: [formRules, deviceMixin, commonFunctions],
  props: ['storeItem', 'price', 'status'],
  data() {
    return {
      menu1: false,
      menu2: false,
      menu3: false,
      dialog: false,
      loading: false,
      date: '',
      time: '',
      showPayment: false,
      requestSent: false,
      fields: {},
      menuItem: null,
      menuOptions: [],
      currentPrice: '----',
      loadingPrice: false,
    }
  },
  computed: {
    ...mapState({
      reservationBasicInfo: state => state.tourist.reservationBasicInfo,
      user: state => state.user,
    }),
    priceUnits() {
      if (this.price.per_hour) {
        return `$${this.price.per_hour}/hour`
      }
      if (this.price.per_day) {
        return `$${this.price.per_day}/day`
      }
      return ''
    },
    hasSpecifications() {
      return !isEmpty(this.menuOptions)
    },
    isDisabled() {
      return DISABLED_STORE_ITEM_STATUSES.includes(this.status)
    },
    buttonText() {
      if (this.isDisabled) {
        return this.status.replace('_', ' ')
      }
      return 'View'
    },
    itemDescription() {
      if (this.status === 'future') {
        return `Will be available ${moment
          .utc(this.storeItem.available_from)
          .format('MMM Do')}`
      }
      if (this.status === 'in_bag') {
        return 'Already in cart'
      }
      return ''
    },
  },
  watch: {
    async dialog(newVal, oldVal) {
      if (newVal && !oldVal) {
        const { data } = await axios.get(
          `/api/tourist/reservations/${this.$route.params.id}/store-items/${this.storeItem.id}/options`
        )
        this.menuOptions = data.map(item => ({
          text: item.name,
          value: item,
        }))
      }
    },
  },
  created() {
    if (this.fields.range) {
      this.fields = [this.reservationBasicInfo.check_in]
    }
  },
  methods: {
    ...mapActions(['logAction']),
    close() {
      this.dialog = false
      this.loading = false
      this.requestSent = false
      this.fields = {}
      this.currentPrice = '----'
      this.menuItem = null
    },
    shouldCloseOnClick() {
      return this.fields.range && this.fields.range.length === 2
    },
    rangeText(dates) {
      if (dates && dates.length) {
        const datesParsed = sort(a => {
          return new Date(a)
        }, dates)
        const [from = '', to = ''] = datesParsed

        return `${from} - ${to}`
      }
      return ''
    },
    async calcPrice() {
      this.loadingPrice = true
      try {
        const { data } = await axios.get(
          `/api/tourist/store-items-menu/${this.menuItem.id}/price`,
          {
            params: this.fields,
          }
        )
        this.currentPrice = data.total_price.toLocaleString('en')
        this.showPayment = true
        this.loadingPrice = false
      } catch (e) {
        this.showPayment = false
        this.loadingPrice = false
      }
    },
    formatDate(item) {
      const date = moment.utc(item)
      const startDate =
        date.format('HH:mm') === '00:00'
          ? date.format('ddd, MMM Do')
          : date.format('ddd, MMM Do, HH:mm')
      if (this.storeItem.duration) {
        return `${startDate} - ${moment
          .utc(item)
          .add(this.storeItem.duration, 'hour')
          .format('HH:mm')}`
      }
      return startDate
    },
    parseLabel({ label, units }) {
      if (units === 'datetime') {
        return 'Select date & time'
      }
      return label.replace('_', ' ')
    },
    async createAuthorizationRequest() {
      this.logAction({
        eventType: HOTJAR_EVENTS.COMPOSE_ORDER_ATTEMPT,
        storeItemId: this.storeItem.id,
      })
      if (
        !this.hasSpecifications ||
        (this.hasSpecifications && this.$refs.form.validate())
      ) {
        this.loading = true
        const contactDetails = {
          email: this.user.email,
          phone: this.user.phone,
        }
        const purchase = await this.$store.dispatch(
          'createAuthorizationRequest',
          {
            reservationId: this.$route.params.id,
            menuId: this.menuItem.id,
            query: { ...this.$route.query },
            options: { ...this.fields, ...contactDetails },
          }
        )
        this.logAction({
          eventType: HOTJAR_EVENTS.COMPOSE_ORDER_SUCCESSFUL,
          storeItemId: this.storeItem.id,
        })
        this.$router.push({
          name: this.hasAbility('create-store-purchase')
            ? 'purchase-request-success'
            : 'store-checkout',
          query: { ...this.$route.query },
          params: { id: purchase.payment_intent.payment_intent_id },
        })
        setTimeout(() => {
          this.dialog = false
          this.loading = false
        }, 2000)
      }
    },
    async itemClicked() {
      this.logAction({
        eventType: HOTJAR_EVENTS.VIEW_ITEM,
        storeItemid: this.storeItem.id,
      })
    },
  },
}
</script>

<style scoped>
.store-item-box {
  border: 1px solid var(--v-secondary-lighten1);
}
.store-item-box:hover {
  box-shadow: 0px 5px 10px #00000012 !important;
}
.card-container {
  overflow: scroll;
}
</style>
