<template>
  <Lottie
    :options="defaultOptions"
    :height="400"
    style="max-width: 250px"
    renderer="html"
    @animCreated="handleAnimation"
  />
</template>
<script>
import Lottie from 'vue-lottie'
import animationData from '@/assets/images/easyair-loader.json'

export default {
  name: 'DvrLoader',
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: {
        animationData,
      },
      anim: null,
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
      this.anim.setSpeed(1.25)
    },
  },
}
</script>
