<template>
  <div class="image-view d-flex" @mouseleave="outPic">
    <img
      v-if="!isVideo(src)"
      :width="smallWidth"
      style="object-fit: cover"
      :src="src"
      alt=""
      @mouseover="hoverPic"
      @click="clicked"
      @load="onLoad"
    />
    <video
      v-else
      :width="smallWidth"
      :src="src"
      :autoplay="false"
      :height="smallWidth"
      @click="clicked"
      @mouseover="hoverPic"
      @canplay="onLoad"
    />
    <v-icon
      v-if="isVideo(src)"
      x-small
      color="white"
      class="play-icon rounded"
      @click="clicked"
      >fas fa-play
    </v-icon>
    <v-progress-circular v-if="!loaded" indeterminate color="primary" />
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions'

export default {
  name: 'ImageView',
  mixins: [CommonFunctions],
  props: ['src', 'smallWidth', 'largeWidth', 'onRemove', 'readonly'],
  data() {
    return {
      hovered: false,
      pdfDialog: false,
      loaded: false,
    }
  },
  methods: {
    onLoad() {
      this.loaded = true
    },
    hoverPic() {
      this.hovered = true
    },
    outPic() {
      this.hovered = false
    },
    clicked() {
      this.$emit('click', this.src)
    },
  },
}
</script>

<style lang="scss" scoped>
.image-view {
  position: relative;

  > .action-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100%;
    z-index: 100;
  }
}

.play-icon {
  position: absolute;
  padding: 6px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: red;
}
</style>
