<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="d-flex dvr-datetime text-caption flex-column pa-2"
          v-on="on"
        >
          <div class="flex text-caption text-uppercase">{{ $t('Guests') }}</div>
          <div class="flex text-body-2 font-weight-medium">
            {{ totalGuests }} {{ $t('Guests') }}
          </div>
        </div>
      </template>
      <v-card min-width="230" width="400" elevation="1">
        <div class="pa-3">
          <div class="d-flex py-1">
            <AddRemoveItem
              :label="$t('Adults')"
              :value.sync="guests.adults"
              :description="$t('From 13 years')"
            />
          </div>
          <v-divider class="my-2" />
          <AddRemoveItem
            :label="$t('Kids')"
            :value.sync="guests.kids"
            :description="$t('2-12 years')"
          />
          <v-divider class="my-2" />
          <div class="d-flex py-1">
            <AddRemoveItem
              :label="$t('Babies')"
              :value.sync="guests.babies"
              :description="$t('Up to 2 years')"
            />
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import AddRemoveItem from '@/components/common/AddRemoveItem'
import sum from 'lodash/fp/sum'
export default {
  name: 'GuestsSelect',
  components: { AddRemoveItem },
  props: {
    borderRadius: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Object,
    },
    format: {
      type: String,
      default: 'D MMMM',
    },
  },
  data() {
    return {
      menu: false,
      guests: this.value,
    }
  },
  computed: {
    totalGuests() {
      return sum(Object.values(this.guests))
    },
  },
  watch: {
    totalGuests() {
      this.$emit('update:value', this.guests)
    },
  },
}
</script>

<style lang="scss" scoped>
.dvr-datetime {
  height: 60px;
  border-radius: 8px;
  width: 200px;
  border: 1px solid #d5d5d5;
}
</style>
