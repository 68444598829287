<template>
  <v-expansion-panels :disabled="disabled" class="my-3">
    <v-expansion-panel v-if="touristData.checkin_instructions">
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div>
          <v-icon class="px-3"> $info_circle </v-icon>{{ $t('Instructions') }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div>{{ touristData.checkin_instructions }}</div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel
      v-if="touristData.guests_info && touristData.guests_info.length"
    >
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div><v-icon class="px-3"> $video </v-icon>Instructional Media</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col
            v-for="info in touristData.guests_info"
            :key="info.id"
            cols="12"
            class="overflow-hidden"
          >
            <div class="text-subtitle-1 text-capitalize mt-2 pb-2">
              {{ info.comments }}
            </div>
            <media-smart-display
              v-for="image in info.images"
              :key="image"
              :media="image"
            ></media-smart-display>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel :disabled="!hasDoorCodes">
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div>
          <v-icon class="px-3"> $door_key </v-icon>{{ $t('DoorCodes') }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(val, key) in doorCodes" :key="key">
          <div v-if="val" class="d-flex align-center">
            <v-icon v-if="key === 'Alert'" size="20" color="error">
              fas fa-exclamation-triangle
            </v-icon>
            <span v-else class="text-body-1 text-capitalize">{{ key }}:</span>
            <span
              :class="[
                'body-2',
                'ml-2',
                key === 'Alert' ? 'error--text' : 'bolder',
              ]"
            >
              {{ val }}</span
            >
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div><v-icon class="px-3"> $wifi </v-icon>{{ $t('Wifi') }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(val, key) in touristData.info.wifi" :key="key">
          <div class="d-flex align-center">
            <v-icon v-if="key === 'Alert'" size="20" color="error">
              fas fa-exclamation-triangle
            </v-icon>
            <span v-else class="text-body-1 text-capitalize">{{ key }}:</span>
            <span
              :class="[
                'body-2',
                'ml-2',
                key === 'Alert' ? 'error--text' : 'bolder',
              ]"
            >
              {{ val }}</span
            >
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div><v-icon class="px-3"> $phone_call </v-icon>{{ $t('Phones') }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(phones, key) in touristData.info.phones" :key="key">
          <div>
            <v-icon v-if="key === 'Alert'" size="20">
              fas fa-exclamation-triangle
            </v-icon>
            <div v-else class="text-body-1 font-weight-medium text-capitalize">
              {{ key }}
            </div>
          </div>
          <div
            v-for="phone in phones"
            :key="phone"
            :class="['body-2', key === 'Alert' ? 'error--text' : 'bolder']"
          >
            {{ phone }}
          </div>
        </div>
        <div class="text-body-1 error--text mt-2">
          {{ $t('NonEmergencyUseChat') }}
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import filter from 'lodash/fp/filter'
import MediaSmartDisplay from 'components/media-smart-display.vue'
export default {
  name: 'BookingCodes',
  components: { MediaSmartDisplay },
  // components: { VideoPlayer },
  props: ['touristData', 'disabled'],
  computed: {
    doorCodes() {
      return this.touristData.info['door_codes']
    },
    hasDoorCodes() {
      return filter(Boolean, this.doorCodes).length !== 0
    },
  },
}
</script>

<style scoped>
.theme--light.v-icon {
  color: inherit;
}
</style>
