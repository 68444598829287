<template>
  <v-card class="text-center" elevation="0">
    <div v-if="!emailSent">
      <!--      <img-->
      <!--        src="@/assets/images/designedvr.png"-->
      <!--        class="company-icon"-->
      <!--        height="64"-->
      <!--        alt="A couple sitting on the beach towards the sea and holding each other's hands"-->
      <!--      />-->
      <h3 class="text-h4 font-weight-bold text-center mb-5">
        {{ $t('ForgotPasswordTitle') }}
      </h3>
      <p class="mb-10">
        {{ $t('ForgotPasswordDescription') }}
      </p>

      <v-form ref="forgotPassword" lazy-validation @submit="forgotPassword">
        <v-text-field
          v-model="emailPassword"
          name="email"
          :rules="emailRules"
          label="Email"
          type="text"
          outlined
          dense
        />

        <v-row class="flex-row-reverse mt-3">
          <v-col cols="6" class="py-0">
            <v-btn
              class="text-capitalize"
              color="primary"
              type="submit"
              :loading="emailLoading"
              block
              elevation="0"
              large
            >
              Confirm
            </v-btn>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-btn
              class="text-capitalize"
              color="primary"
              block
              outlined
              large
              @click="onClose"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-else class="text-center">
      <v-icon :size="isMobile ? 180 : 260"> $email_sent </v-icon>
      <h3 class="text-h5 text-sm-h4 font-weight-bold mb-5">
        {{ $t('EmailSent') }}
      </h3>
      <p class="mb-6">
        {{ $t('EmailSentDescription', { email: emailPassword }) }}
      </p>
      <v-btn
        class="text-capitalize mb-4"
        color="primary"
        elevation="0"
        block
        large
        @click="onClose"
      >
        {{ $t('OK') }}
      </v-btn>
      <div class="d-flex justify-center align-center">
        {{ $t('DidntReceiveLink') }}
        <v-btn
          color="primary"
          class="text-capitalize"
          text
          @click="emailSent = false"
        >
          {{ $t('Resend') }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios'
import formRules from 'components/mixins/form-rules-mixin'
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'ForgotPassword',
  mixins: [formRules, deviceMixin],
  data() {
    return {
      emailPassword: '',
      emailSent: false,
      emailLoading: false,
    }
  },
  methods: {
    forgotPassword(e) {
      e.preventDefault()
      if (this.$refs.forgotPassword.validate()) {
        this.emailLoading = true
        axios
          .post('/api/forgot-password', { email: this.emailPassword })
          .then(() => {
            this.emailLoading = false
            this.emailSent = true
          })
          .catch(error => {
            this.emailLoading = false
            this.emailSent = false
            console.log(error)
          })
      }
    },
    onClose() {
      this.emailPassword = ''
      this.emailLoading = false
      this.emailSent = false
      this.$emit('on-forgot-password-close')
    },
  },
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  min-height: 100%;
}
</style>
