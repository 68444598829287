<template>
  <div>
    <v-card class="pa-4 mb-3 mx-auto">
      <ReservationCard
        :reservation="reservation"
        :show-info="false"
        class="mb-2"
      />
      <div class="d-flex justify-space-between text-subtitle-1 py-2">
        <div>
          <div>{{ item.item_name }}</div>
          <div class="text-caption secondary--text">
            {{ item.description }}
          </div>
          <div
            v-if="
              item.extra_data &&
              item.extra_data.category &&
              item.extra_data.description
            "
          >
            {{ item.extra_data.category }} - {{ item.extra_data.description }}
          </div>
        </div>
        <div>{{ toDollar(item.amount) }}</div>
      </div>
    </v-card>
  </div>
</template>

<script>
import ReservationCard from 'components/common/reservation-card'
import CommonFunctions from 'components/mixins/common-functions'

export default {
  name: 'StoreCheckoutSummary',
  components: { ReservationCard },
  mixins: [CommonFunctions],
  props: ['item', 'reservation'],
}
</script>

<style scoped></style>
