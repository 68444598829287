export const DISABLED_STORE_ITEM_STATUSES = [
  'not_available',
  'in_bag',
  'purchased',
  'future',
]

export const HTTP_STATUS = {
  NOT_AUTHORIZED: 401,
  NOT_FOUND: 404,
}

export const URLS = {
  DESIGNEDVR: 'https://designedvr.com/',
}
