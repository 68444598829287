<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    max-width="500px"
    class="rounded-xl"
  >
    <template #activator="{ on }">
      <div class="text-caption secondary--text">
        {{ itemDescription }}
      </div>
      <v-btn
        color="primary"
        elevation="0"
        :disabled="isDisabled"
        block
        class="rounded text-subtitle-2 darken-1 font-weight-bold white--text"
        v-on="on"
        @click="itemClicked"
      >
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card>
      <v-avatar tile size="80" class="rounded ml-6 mt-16">
        <img :src="formatImageUrl(storeItem.image)" alt="" />
      </v-avatar>
      <v-btn
        style="z-index: 10"
        icon
        light
        :fixed="isMobile"
        :absolute="!isMobile"
        top
        left
        @click="dialog = false"
      >
        <v-icon color="grey darken-2"> fas fa-arrow-left </v-icon>
      </v-btn>
      <v-card-title
        class="text-subtitle-1 font-weight-bold black--text d-flex text-h5 justify-space-between"
      >
        <div class="text-sm-h6">
          {{ storeItem.name }}
        </div>
        <div class="indigo--text text--darken-4">
          {{ priceUnits }}
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-4 black--text card-container">
        <v-form v-if="hasSpecifications" ref="form" class="pt-3">
          <div v-for="option in options" :key="option.key">
            <div v-if="option.units === 'datetime' && option.type === 'select'">
              <div class="text-subtitle-1 font-weight-medium">
                {{ parseLabel(option) }}
              </div>
              <v-select
                v-model="fields[option.key]"
                :rules="[required]"
                outlined
                color="black"
                :items="option.value"
              >
                <template #selection="{ item }">
                  <span>{{ formatDate(item) }}</span>
                </template>
                <template #item="{ item }">
                  <span>{{ formatDate(item) }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </v-form>
        <div class="text-subtitle-1">Description</div>
        <div class="text-caption">
          {{ storeItem.description }}
        </div>
        <div class="py-3">
          <div v-if="storeItem.bullets" class="text-subtitle-1">
            What's included?
          </div>
          <div v-if="storeItem.bullets" class="text-caption d-flex">
            <v-row no-gutters class="flex-wrap">
              <v-col v-for="i in storeItem.bullets" :key="i" cols="12" sm="6">
                <li class="text-capitalize">
                  {{ i }}
                </li>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="storeItem.disclaimer" class="pb-3">
          <div class="text-subtitle-1">Disclaimer</div>
          <div class="text-caption">
            {{ storeItem.disclaimer }}
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="flex-wrap pa-5">
        <div
          class="d-flex py-2 flex text-subtitle-1 font-weight-medium justify-space-between"
        >
          <div>Total</div>
          <div>${{ price.total }}</div>
        </div>
        <v-btn
          :loading="loading"
          color="primary"
          elevation="0"
          :disabled="isDisabled"
          block
          class="rounded text-subtitle-2 darken-1 font-weight-bold white--text"
          @click="placeOrder"
        >
          {{
            hasAbility('create-store-purchase')
              ? 'Create Payment Request'
              : 'Reserve'
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isEmpty from 'lodash/fp/isEmpty'
import { DISABLED_STORE_ITEM_STATUSES } from '@/consts'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'
import formRules from 'components/mixins/form-rules-mixin'
import { mapActions } from 'vuex'
import deviceMixin from 'components/mixins/deviceMixin'
import moment from 'moment'
import CommonFunctions from 'components/mixins/common-functions'
export default {
  name: 'StoreItemDialog',
  mixins: [formRules, deviceMixin, CommonFunctions],
  props: ['storeItem', 'price', 'options', 'status'],
  data() {
    const fields = this.options.reduce((acc, op) => {
      acc[op.key] = null
      return acc
    }, {})
    return {
      dialog: false,
      loading: false,
      fields,
    }
  },
  computed: {
    priceUnits() {
      if (this.price.per_hour) {
        return `$${this.price.per_hour} / hour`
      }
      if (this.price.per_day) {
        return `$${this.price.per_day} / day`
      }
      return ''
    },
    hasSpecifications() {
      return !isEmpty(this.options)
    },
    isDisabled() {
      return DISABLED_STORE_ITEM_STATUSES.includes(this.status)
    },
    buttonText() {
      if (this.isDisabled) {
        return this.status.replace('_', ' ')
      }
      return 'View'
    },
    itemDescription() {
      if (this.status === 'future') {
        return `Will be available ${moment
          .utc(this.storeItem.available_from)
          .format('MMM Do')}`
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['logAction']),
    formatDate(item) {
      const startDate = moment.utc(item).format('ddd, MMM Do, HH:mm')
      if (this.storeItem.duration) {
        return `${startDate} - ${moment
          .utc(item)
          .add(this.storeItem.duration, 'hour')
          .format('HH:mm')}`
      }
      return startDate
    },
    parseLabel({ label, units }) {
      if (units === 'datetime') {
        return 'Select date & time'
      }
      return label.replace('_', ' ')
    },
    async placeOrder() {
      this.logAction({
        eventType: HOTJAR_EVENTS.COMPOSE_ORDER_ATTEMPT,
        storeItemId: this.storeItem.id,
      })
      if (
        !this.hasSpecifications ||
        (this.hasSpecifications && this.$refs.form.validate())
      ) {
        this.loading = true
        const purchase = await this.$store.dispatch('placeOrder', {
          reservationId: this.$route.params.id,
          itemId: this.storeItem.id,
          options: this.fields,
        })
        this.logAction({
          eventType: HOTJAR_EVENTS.COMPOSE_ORDER_SUCCESSFUL,
          storeItemId: this.storeItem.id,
        })
        this.$router.push({
          name: this.hasAbility('create-store-purchase')
            ? 'purchase-request-success'
            : 'store-checkout',
          query: { ...this.$route.query },
          params: { id: purchase.payment_intent.payment_intent_id },
        })
      }
    },
    itemClicked() {
      this.logAction({
        eventType: HOTJAR_EVENTS.VIEW_ITEM,
        storeItemId: this.storeItem.id,
      })
    },
  },
}
</script>

<style scoped>
.card-container {
  overflow: scroll;
}
</style>
