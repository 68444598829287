<template>
  <v-row
    class="grey lighten-5 text-center pa-3 d-flex justify-center flex-wrap flex-sm-nowrap"
  >
    <v-col class="flex">
      <span v-show="!value" class="my-3"
        ><v-icon small>fas fa-images</v-icon></span
      >
      <div class="font-weight-medium text-subtitle-2">
        {{ title }}
      </div>
      <v-file-input
        ref="file"
        v-model="files"
        type="file"
        style="display: none"
        accept="image/*"
        @change="readURL"
      />
      <v-sheet
        outlined
        height="160"
        width="250"
        class="mx-auto ma-3 p-relative dashed-border"
      >
        <v-overlay
          v-if="filesLoading"
          :opacity="0.1"
          absolute
          class="flex-center"
        >
          <v-progress-circular color="primary" indeterminate size="50" />
        </v-overlay>
        <v-img
          contain
          width="100%"
          height="100%"
          class="rounded"
          :src="value"
        />
      </v-sheet>
      <v-btn
        outlined
        color="black"
        class="my-3"
        small
        :disabled="filesLoading"
        @click="pickFile"
        >{{ $t(`Choose ${value ? 'another' : ''} file`) }}</v-btn
      >
    </v-col>
    <slot name="instructions" />
  </v-row>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ProfileUpload',
  props: ['value', 'title'],
  data() {
    return {
      files: [],
      picHovered: false,
      filesLoading: false,
      imageProgress: 0,
    }
  },
  methods: {
    hoverPic() {
      this.picHovered = true
    },
    outPic() {
      this.picHovered = false
    },
    readURL() {
      this.filesLoading = true
      const formData = new FormData()
      formData.append('upload_preset', 'ie2mgrcv')
      formData.append('file', this.files)
      return axios
        .post('https://api.cloudinary.com/v1_1/do4tedxg6/upload', formData, {
          withCredentials: false,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / this.files.size
            )
            this.imageProgress = progress > 100 ? 100 : progress
          },
        })
        .then(result => {
          const urls = result.data.secure_url
          this.$emit('update:value', urls)
          this.$emit('on-upload', urls)
        })
        .catch(err => (this.error = err))
        .then(() => {
          this.filesLoading = false
        })
    },
    pickFile() {
      this.$refs.file.$refs.input.click()
    },
  },
}
</script>

<style scoped>
.upload-box {
  border: 1px solid var(--v-secondary-lighten1);
  position: relative;
  cursor: pointer;
}
.dashed-border {
  border: 1px dashed black !important;
  background-color: transparent !important;
  border-radius: 10px;
}
</style>
