<template>
  <div class="mb-6">
    <p class="mb-0">{{ $t(item.text) }}</p>
    <v-textarea
      v-if="item.value === 'feedback'"
      v-model="feedback"
      class="mt-2"
      :placeholder="$t('Feedback')"
      outlined
      hide-details
      @input="update(item.value, feedback)"
    />
    <v-chip-group
      v-else
      v-model="point"
      class="review-chip-group"
      @change="update(item.value, point)"
    >
      <v-chip
        v-for="index in marks"
        :key="index"
        outlined
        class="px-4 py-6"
        :value="index"
        :color="point === index ? rateColor[index] : ''"
        label
      >
        <v-icon :color="rateColor[index]">{{ rateIcons[index] }}</v-icon>
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'ReviewFormItem',
  mixins: [deviceMixin],
  props: {
    item: Object,
  },
  data() {
    return {
      marks: 5,
      point: null,
      feedback: '',
      rateIcons: {
        5: 'mdi-emoticon',
        4: 'mdi-emoticon-happy',
        3: 'mdi-emoticon-neutral',
        2: 'mdi-emoticon-sad',
        1: 'mdi-emoticon-dead',
      },
      rateColor: {
        5: '#57e32c',
        4: '#b7dd29',
        3: '#ffe234',
        2: '#ffa534',
        1: '#ff4545',
      },
    }
  },
  methods: {
    update(field, value) {
      this.$emit('update', { field, value })
    },
  },
}
</script>

<style lang="scss">
.review-chip-group .v-slide-group__content {
  justify-content: space-between;
}
</style>
