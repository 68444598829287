<template>
  <div>
    <iframe
      v-if="isPdf(media)"
      width="100%"
      height="500"
      frameborder="0"
      :src="media"
    ></iframe>
    <img
      v-if="!isPdf(media) && isImage(media)"
      style="width: 100%"
      :src="media"
      class="image-design"
    />
    <video-player
      v-if="isVideo(media) && !isImage(media)"
      :src="media"
      :width="370"
      :height="370"
    ></video-player>
  </div>
</template>

<script>
import VideoPlayer from 'components/common/video-player'

export default {
  components: { VideoPlayer },
  props: ['media', 'deleteImg', 'rotate'],
  methods: {
    isPdf(src) {
      if (src && src.indexOf('.pdf') != -1) {
        return true
      } else {
        return false
      }
    },
    isImage(src) {
      if (
        src &&
        (src.indexOf('.jpg') != -1 ||
          src.indexOf('.gif') != -1 ||
          src.indexOf('.bmp') != -1 ||
          src.indexOf('.png') != -1)
      ) {
        return true
      } else {
        return false
      }
    },
    isVideo(src) {
      return (
        src &&
        (src.indexOf('.m4v') != -1 ||
          src.indexOf('.avi') != -1 ||
          src.indexOf('.mpg') != -1 ||
          src.indexOf('.mp4') != -1 ||
          src.indexOf('.mkv') != -1 ||
          src.indexOf('.mov') != -1)
      )
    },
  },
}
</script>
