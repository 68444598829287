<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="flex dvr-datetime text-caption flex-column pa-2"
          v-on="on"
        >
          <div class="flex text-caption text-uppercase">
            {{ label }}
          </div>
          <div class="flex text-body-2 font-weight-medium">
            {{ date }}
          </div>
        </div>
      </template>
      <v-time-picker
        v-model="date"
        ampm-in-title
        format="ampm"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedStep"
        @change="changeDate"
        @click:minute="$refs.menu.save(date)"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'DvrTimePicker',
  props: {
    borderRadius: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    format: {
      type: String,
      default: 'HH:mm',
    },
    allowedHours: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      date: this.value,
      menu: false,
    }
  },
  computed: {
    guestAmount() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
    },
    allowedCheckInHours() {
      return [16, 17, 18, 19, 20, 21, 22, 23]
    },
    allowedCheckoutHours() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
  },
  methods: {
    changeDate(val) {
      this.$emit('update:value', val)
    },
    allowedStep: m => m % 30 === 0,
  },
}
</script>

<style scoped>
.dvr-datetime {
  height: 60px;
  border-radius: 8px;
  width: 150px;
  border: 1px solid #d5d5d5;
}
</style>
