<template>
  <v-container>
    <div class="d-flex flex flex-column map-wrapper">
      <v-dialog
        v-model="dialog"
        :fullscreen="isMobile"
        persistent
        max-width="450px"
      >
        <v-card
          class="d-flex pa-7 justify-center align-center"
          style="position: relative"
          height="450"
        >
          <v-col v-if="status === 'processing'" cols="9">
            <DvrLoader />
          </v-col>
          <div
            v-else
            class="d-flex flex-column justify-center align-center flex"
          >
            <img src="@/assets/images/payment-success.png" width="200" alt="" />
            <div class="text-h6 my-3">{{ $t('PaymentSuccessful') }}</div>
            <div class="text-caption my-3 px-7 px-sm-3">
              {{ $t('BookingConfirmed') }}
              {{ $t('InvoiceSent') }}
            </div>
            <v-btn class="mt-3 px-10" color="primary" @click="goToReservation">
              OK
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <div v-if="isDeposit" class="text-h4 py-4 px-0">
        {{ $t('BookingDeposit') }}
      </div>
      <div v-else class="text-h4 py-4 px-0">{{ $t('Checkout') }}</div>
      <div v-if="isDeposit" pa-3 class="text-caption">
        {{ $t('Hello') }} <b>{{ reservation.guest.full_name }}</b
        >, {{ $t('Thank You For Choosing') }} {{ listingName }}
        {{ $t('For Your Vacation Rental Stay') }}! <br />
        {{ $t('We Are Notifying You') }}
        <b>{{ toCurrency(payment.amount / 100) }}</b>
        {{ $t('Will Be Placed On The Credit Card') }}
        {{
          formatDate(
            reservation.check_in,
            'YY/MM/DD',
            reservation.listing.timezone
          )
        }}
        -
        {{
          formatDate(
            reservation.check_out,
            'YY/MM/DD',
            reservation.listing.timezone
          )
        }}. {{ $t('ThisHoldWillBeReleased') }}
      </div>
      <div v-else class="text-caption">
        <span
          >{{ $t('Hello') }} <b>{{ reservation.guest.full_name }}</b
          >, {{ $t('ThankYouForChoosing') }} {{ listingName }}
          {{ $t('ForYourVacationRentalStay') }}! <br />
          {{ $t('ToCompleteYourBooking') }}</span
        ><span v-if="!$vuetify.breakpoint.xs">
          {{ $t('AfterCompletion') }}
          {{ $t('WeLookForwardToHostingYou') }}</span
        >
      </div>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-card class="mx-auto" max-width="800" elevation="0">
            <v-card-text class="px-0">
              <StripeElement
                v-if="publicKey"
                :public-key="publicKey"
                :client-secret="clientSecret"
                button-text="Confirm & pay"
                @payment-success="paymentSuccess"
                @payment-processing="paymentProcessing"
                @payment-failed="paymentFailed"
              >
                <ReservationCheckoutSummary
                  v-if="isMobileOrIPad"
                  :reservation="reservation"
                  :payment="payment"
                />
              </StripeElement>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="0" md="1" />
        <v-col v-if="!isMobileOrIPad" cols="12" md="5">
          <div class="d-flex justify-center">
            <v-col cols="12" xl="8" class="ml-md-8 pa-0">
              <ReservationCheckoutSummary
                :reservation="reservation"
                :payment="payment"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import StripeElement from 'components/stripe/StripeCustomElement'
import deviceMixin from 'components/mixins/deviceMixin'
import commonFunctions from 'components/mixins/common-functions'
import DvrLoader from '@/assets/icons/dvr-loader'
import axios from 'axios'
import ReservationCheckoutSummary from '@/components/reservations/reservation-checkout-summary'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'
import { mapActions } from 'vuex'
import get from 'lodash/fp/get'

export default {
  name: 'ReservationCheckout',
  components: {
    ReservationCheckoutSummary,
    DvrLoader,
    StripeElement,
  },
  mixins: [deviceMixin, commonFunctions],
  data() {
    return {
      dialog: false,
      status: null,
      mainReservationCode: null,
      clientSecret: '',
      publicKey: '',
      listing: {},
      payment: {},
    }
  },
  computed: {
    paymentIntentId() {
      return this.$route.params.intent_id
    },
    isDeposit() {
      return this.payment.capture_method === 'manual'
    },
    listingName() {
      return get('listing.resort_name', this.reservation)
    },
    reservation() {
      return (
        this.$store.getters.currentReservation || {
          invoice_items: [],
          guest: {},
        }
      )
    },
  },
  async mounted() {
    try {
      const {
        data: { payment, stripe_public_key },
      } = await axios.get(`/api/checkout-info/${this.paymentIntentId}`)

      if (
        payment.status === 'succeeded' ||
        payment.status === 'requires_capture' ||
        (this.reservation.balance_due === 0 &&
          payment.metadata.type === 'accommodation')
      ) {
        this.status = 'success'
        this.dialog = true
        return
      }
      this.clientSecret = payment.client_secret
      this.publicKey = stripe_public_key
      this.payment = payment
    } catch (err) {
      this.$store.commit('updateLoading', false)
      if (err.response.status === 404) {
        this.$store.commit('showNotFound')
      }
    }
  },
  methods: {
    ...mapActions(['logAction']),
    paymentSuccess({ user }) {
      this.logAction({
        eventType: HOTJAR_EVENTS.PAYMENT_SUCCESSFUL,
        reservationId: this.reservation.id,
      })
      this.dialog = true
      setTimeout(() => {
        this.$store.dispatch('getTouristTripInfo', this.$route.params.id)
        this.$store.dispatch('getReservations')
        this.$store.dispatch('getTouristPreCheckIn', this.$route.params.id)
        this.status = 'success'
        this.user = user
      }, 3000)
    },
    paymentProcessing() {
      this.logAction({
        eventType: HOTJAR_EVENTS.PAYMENT_ATTEMPT,
        reservationId: this.reservation.id,
      })
      this.status = 'processing'
      this.dialog = true
    },
    paymentFailed() {
      this.dialog = false
      this.status = 'failed'
    },
    goToReservation() {
      this.$router.push({
        name: 'reservation-info',
        params: {
          id: this.reservation.confirmation_code,
        },
      })
    },
  },
}
</script>

<style scoped></style>
