<template>
  <div class="group-wrapper d-flex flex">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MenuGroup',
}
</script>

<style scoped>
.group-wrapper > div:first-child >>> div:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group-wrapper > div:last-child >>> div:first-child {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
