<template>
  <video
    :id="'example-player' + myId"
    ref="videoPlayer"
    controls
    muted
    class="cld-video-player cld-video-player-skin-dark"
  />
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions.js'
import cloudinary from 'cloudinary-core'

export default {
  mixins: [CommonFunctions],
  props: ['src', 'width', 'height'],
  data() {
    return {
      myId: Math.floor(Math.random() * 100000) + 1,
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.cloudinaryInstance = cloudinary.Cloudinary.new({
      cloud_name: 'Designedvr', // Replace with your Cloudinary cloud name
    })

    // Get the video URL from Cloudinary
    const videoUrl = this.cloudinaryInstance.url(this.src, {
      resource_type: 'video',
    })

    // Set the video source
    const videoElement = this.$refs.videoPlayer
    videoElement.src = videoUrl
  },
  methods: {},
}
</script>
