import InfoIcon from '@/assets/icons/info'
import WifiIcon from '@/assets/icons/wifi'
import PhoneCallIcon from '@/assets/icons/phone-call'
import DoorKeyIcon from '@/assets/icons/door-key'
import VideoIcon from '@/assets/icons/video'
import LeftArrowIcon from '@/assets/icons/left-arrow'
import RightArrowIcon from '@/assets/icons/right-arrow'
import MapDirectionsIcon from '@/assets/icons/map-directions'
import ShoppingCartIcon from '@/assets/icons/shopping-cart'
import AddIcon from '@/assets/icons/add'
import MinusIcon from '@/assets/icons/minus'
import LongRightArrowIcon from '@/assets/icons/long-right-arrow'
import LongLeftArrowIcon from '@/assets/icons/long-left-arrow'
import NotFound from '@/assets/icons/not_found'
import FacebookIcon from '@/assets/icons/facebook'
import GoogleIcon from '@/assets/icons/google'
import EmailSent from '@/assets/icons/email-sent'
import FiltersIcon from '@/assets/icons/filters'
import MapMarkerIcon from '@/assets/icons/map-marker'
import Airbnb from '@/assets/icons/airbnb.vue'

export default {
  values: {
    checkboxOff: 'far fa-square',
    info_circle: {
      component: InfoIcon,
    },
    door_key: {
      component: DoorKeyIcon,
    },
    phone_call: {
      component: PhoneCallIcon,
      props: {
        // pass props to your component if needed
        color: 'grey',
      },
    },
    wifi: {
      component: WifiIcon,
    },
    video: {
      component: VideoIcon,
    },
    left_arrow: {
      component: LeftArrowIcon,
    },
    right_arrow: {
      component: RightArrowIcon,
    },
    long_right_arrow: {
      component: LongRightArrowIcon,
    },
    long_left_arrow: {
      component: LongLeftArrowIcon,
    },
    map_directions: {
      component: MapDirectionsIcon,
    },
    shopping_cart: {
      component: ShoppingCartIcon,
    },
    add: {
      component: AddIcon,
    },
    minus: {
      component: MinusIcon,
    },
    not_found: {
      component: NotFound,
    },
    facebook: {
      component: FacebookIcon,
    },
    google: {
      component: GoogleIcon,
    },
    email_sent: {
      component: EmailSent,
    },
    filters: {
      component: FiltersIcon,
    },
    map_marker: {
      component: MapMarkerIcon,
    },
    airbnb: {
      component: Airbnb,
    },
  },
}
