<template>
  <v-card class="pa-5" :elevation="isMobileOrIPad ? 0 : 2">
    <ReservationCard :show-info="false" :reservation="reservation" />
    <v-col cols="12" class="d-flex no-gutters px-0 mt-8">
      <v-col cols="6">
        <CheckDateBox
          title="$t('Checkin')"
          :date="reservation.check_in"
          :timezone="listing.timezone"
          :time="reservation.planned_arrival"
        />
      </v-col>
      <v-divider vertical />
      <v-col cols="6" class="pl-3">
        <CheckDateBox
          title="$t('Checkout')"
          :date="reservation.check_out"
          :timezone="listing.timezone"
          :time="reservation.planned_departure"
        />
      </v-col>
    </v-col>
    <v-divider class="my-6" />
    <div v-if="!isDeposit">
      <div v-if="reservation.invoice_items.length">
        <div
          v-for="(product, idx) in reservation.invoice_items"
          :key="idx"
          class="d-flex align-center pb-1 justify-space-between"
        >
          <span class="text-body-2 text-capitalize">{{ product.title }}</span>
          <span class="text-body-2">{{ toCurrency(product.amount) }}</span>
        </div>
      </div>
      <div v-else class="flex">
        <div class="d-flex justify-space-between align-center">
          <span class="text-body-2 text-capitalize">
            {{ $t('AdditionalCosts') }}
          </span>
          <span class="text-body-2">{{
            toCurrency(reservation.balance_due)
          }}</span>
        </div>
      </div>
      <v-divider class="my-6" />
      <div class="d-flex justify-space-between flex align-center">
        <span class="text-h6">{{ $t('Total') }}</span>
        <span class="text-h6">{{ toCurrency(reservation.balance_due) }}</span>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-space-between">
        <span class="text-body-2 text-capitalize"> {{ $t('Deposit') }} </span>
        <span class="text-body-2 text-capitalize">{{
          toCurrency(payment.amount / 100)
        }}</span>
      </div>
      <v-divider class="my-6" />
      <div class="d-flex justify-space-between flex align-center">
        <span class="text-h6">{{ $t('Total') }}</span>
        <span class="text-h6">{{ toCurrency(payment.amount / 100) }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import CheckDateBox from '@/components/common/check-date-box'
import ReservationCard from '@/components/common/reservation-card'
import deviceMixin from '@/components/mixins/deviceMixin'
import commonFunctions from 'components/mixins/common-functions'

export default {
  name: 'ReservationCheckoutSummary',

  components: { ReservationCard, CheckDateBox },
  mixins: [deviceMixin, commonFunctions],
  props: ['reservation', 'payment'],
  computed: {
    listing() {
      return this.reservation.listing
    },
    isDeposit() {
      return this.payment.capture_method === 'manual'
    },
  },
}
</script>

<style scoped></style>
