import moment from 'moment-timezone'
import { isEmpty, get } from 'lodash/fp'
const CommonFunctions = {
  data: function () {
    return {
      myLoader: 0,
    }
  },
  methods: {
    formatDate(date, format, timezone) {
      if (timezone) {
        return moment(date).tz(timezone).format(format)
      }
      return moment(date).format(format)
    },
    formatReservationPeriod(reservation, format) {
      return `${this.formatDate(
        reservation.check_in,
        format
      )} - ${this.formatDate(reservation.check_out, format)} `
    },
    toCurrency(amount, maximumFractionDigits = 2, currency) {
      if (!amount && amount !== 0) return null
      return new Intl.NumberFormat(`en-US`, {
        style: 'currency',
        currency: currency || get('config.currency', window) || 'USD',
        maximumFractionDigits,
      }).format(amount)
    },
    toDollar(amount) {
      return this.toCurrency(amount, 2, 'USD')
    },
    formatImageUrl(url) {
      return url.replace('/upload/', '/upload/q_auto/f_auto/')
    },
    isVideo(src) {
      return (
        src &&
        (src.indexOf('.m4v') !== -1 ||
          src.indexOf('.avi') !== -1 ||
          src.indexOf('.mpg') !== -1 ||
          src.indexOf('.mp4') !== -1 ||
          src.indexOf('.mov') !== -1)
      )
    },
    hasAbility(ability) {
      const { user } = this.$store.state.user
      if (isEmpty(ability)) {
        return true
      }
      if (user && user.abilities) {
        if (user.abilities.includes('super-admin')) {
          return true
        }
        if (Array.isArray(ability)) {
          return ability.some(ab => user.abilities.includes(ab))
        }
        return user.abilities.includes(ability)
      } else {
        return false
      }
    },
  },
}

export default CommonFunctions
