<template>
  <div class="d-flex flex flex-column map-wrapper">
    <GmapMap
      v-if="!isMobile"
      :center="{ lat: listing.lat, lng: listing.lng }"
      :zoom="16"
      :options="{ disableDefaultUI: true, styles }"
      map-type-id="terrain"
      style="min-height: 500px"
    >
      <GmapMarker
        :key="1"
        :position="{
          lat: listing.lat,
          lng: listing.lng,
        }"
      />
    </GmapMap>
    <v-col
      ref="reservationInfo"
      class="d-flex reservation-info-wrapper pa-0"
      cols="12"
      sm="7"
      md="5"
      lg="4"
    >
      <v-card elevation="0" style="height: 100%" class="overflow-auto">
        <v-overlay
          v-show="shouldPayAccommodation"
          color="white"
          light
          absolute
          :opacity="0.9"
        >
          <v-card
            elevation="0"
            max-width="550"
            min-width="300"
            class="d-flex transparent"
          >
            <v-row class="justify-center align-center flex-column pa-6">
              <v-icon color="warning" size="150" class="pa-2">
                fas fa-credit-card
              </v-icon>
              <div class="pb-6 text-center black--text text-subtitle-2">
                {{ $t('PleaseCompleteBookingPayment') }}
              </div>
              <div>
                <v-btn
                  :to="`/tourist/trips/${reservation.confirmation_code}/checkout/${accommodation.payment_intent_id}`"
                  link
                  color="warning"
                >
                  {{ $t('ProceedToCheckout') }}
                </v-btn>
              </div>
            </v-row>
          </v-card>
        </v-overlay>
        <v-overlay
          v-if="shouldPayDeposit"
          absolute
          color="white"
          class="rounded-xl"
          light
          :opacity="0.9"
        >
          <v-row class="justify-center pa-6">
            <v-icon color="warning" size="100" class="pa-6">
              fas fa-unlock-alt
            </v-icon>
            <div class="px-4 bolder text-subtitle-1 black--text">
              {{ $t('ReservationDetailsAvailability') }}
            </div>
            <div>
              <v-btn
                :to="`/tourist/trips/${reservation.confirmation_code}/checkout/${deposit.payment_intent_id}`"
                link
                color="warning"
              >
                {{ $t('PayDeposit') }}
              </v-btn>
            </div>
          </v-row>
        </v-overlay>
        <v-carousel
          :height="carouselHeight"
          hide-delimiter-background
          next-icon="chevron_right"
          prev-icon="chevron_left"
        >
          <v-carousel-item v-for="(slide, i) in first5Pics" :key="i">
            <v-img :src="slide.large || slide.original" />
          </v-carousel-item>
        </v-carousel>
        <div class="d-flex flex flex-column overflow-y-auto pa-3">
          <div class="d-flex">
            <v-icon class="mr-5"> fas fa-map-marker-alt </v-icon>
            <div>
              <div class="mt-0 text-subtitle-1 text-sm-h6">
                {{ reservation.listing.nickname }}
              </div>
              <div class="text-body-2 secondary--text">
                {{ reservation.listing.city_name }}
              </div>
            </div>
          </div>
          <v-divider class="my-4" />
          <v-row class="px-3" no-gutters>
            <v-col cols-6 class="pl-3">
              <CheckDateBox
                :title="$t('Check-in')"
                :date="reservation.check_in"
                :timezone="listing.timezone"
                :time="reservation.planned_arrival"
              />
            </v-col>
            <v-divider vertical />
            <v-col cols-6 class="pl-3">
              <CheckDateBox
                :title="$t('Checkout')"
                :date="reservation.check_out"
                :timezone="listing.timezone"
                :time="reservation.planned_departure"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row class="no-gutters">
            <v-col cols="12">
              <div
                v-if="touristsData.error"
                class="text-caption font-weight-medium warning--text pb-1"
              >
                <v-icon x-small class="pr-1" color="warning">
                  fas fa-exclamation-triangle
                </v-icon>
                {{ touristsData.error }}
              </div>
            </v-col>
            <v-col>
              <BookingCodes
                :disabled="Boolean(touristsData.error)"
                :tourist-data="touristsData"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row v-if="storeEnabled" class="px-3" no-gutters>
            <div
              class="d-flex align-center text-h6 font-weight-bold primary--text text--darken-1 mb-3"
            >
              <v-icon class="mr-2" color="primary darken-1" small
                >fas fa-plus</v-icon
              >
              Vacation Add-ons
            </div>
            <v-col cols="12">
              <div
                v-if="!purchasedItems.length"
                class="text-center secondary--text text-subtitle-1 py-2"
              >
                {{ $t('NoVacationAddons') }}
              </div>
              <PurchasesReview v-else :items="purchasedItems" />
            </v-col>
            <v-col v-if="!isReservationPassed">
              <v-btn
                block
                dark
                class="white--text"
                depressed
                :to="{
                  name: 'tourist-store',
                  id: reservation.confirmation_code,
                }"
                color="primary"
              >
                {{ $t('BrowseVacationAddons') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div class="px-3">
            <div class="text-h6 font-weight-medium my-2">
              {{ $t('Getting there') }}
            </div>
            <div class="text-subtitle-1 font-weight-medium">
              {{ $t('Address') }}
            </div>
            <div class="text-caption">
              <a :href="googleMapsLink" target="_blank" class="black--text">{{
                reservation.listing.address
              }}</a>
            </div>
            <v-divider class="my-4" />
            <div
              class="d-flex align-center justify-space-between pointer"
              @click="showDirections"
            >
              <div>
                <v-icon class="mr-2" small> $map_directions </v-icon> Get
                {{ $t('GetDirections') }}
              </div>
              <v-icon class="mr-2" x-small> $right_arrow </v-icon>
            </div>
          </div>
          <v-divider class="my-4" />
          <div class="d-flex px-3">
            <pre-check-in-summary />
          </div>
        </div>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import CheckDateBox from 'components/common/check-date-box'
import take from 'lodash/fp/take'
import BookingCodes from 'components/reservations/booking-codes'
import PreCheckInSummary from 'components/reservations/pre-check-in-summary'
import mapStyles from 'components/map-style'
import PurchasesReview from 'components/store/purchases-review'
import featureFlagsMixin from '@/components/mixins/featureFlagsMixin'
import CommonFunctions from '@/components/mixins/common-functions'
import moment from 'moment'
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'ReservationInfo',
  components: {
    PurchasesReview,
    PreCheckInSummary,
    BookingCodes,
    CheckDateBox,
  },
  mixins: [featureFlagsMixin, CommonFunctions, deviceMixin],
  data() {
    return {
      dialog: false,
      carouselHeight: 0,
    }
  },
  computed: {
    styles() {
      return mapStyles
    },
    isReservationPassed() {
      return moment(this.reservation.check_out).isBefore(moment())
    },
    googleMapsLink() {
      return `https://www.google.com/maps/dir/?api=1&destination=${this.reservation.listing.address}`
    },
    touristsData() {
      return this.$store.getters.reservationInfo
    },
    shouldPayDeposit() {
      return this.$store.state.tourist.shouldPlaceDeposit
    },
    shouldPayAccommodation() {
      return (
        this.reservation.is_merchant &&
        this.reservation.balance_due > 0 &&
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            intent.status === 'requires_source'
        )
      )
    },
    reservation() {
      return (
        this.$store.getters.currentReservation || {
          listing: { pictures: [] },
          payment_intents: [],
        }
      )
    },
    listing() {
      return this.reservation.listing
    },
    purchasedItems() {
      return this.$store.getters.purchasedItems
    },
    movies() {
      return this.touristsData.movies
    },
    deposit() {
      return (
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'deposit' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) || {}
      )
    },
    accommodation() {
      return (
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) || {}
      )
    },
    pictures() {
      return this.reservation.listing.pictures.filter(
        p => p.thumbnail && p.original
      )
    },
    first5Pics() {
      return take(5, this.reservation.listing.pictures)
    },
    preCheckIn() {
      return this.reservation.pre_check_in
    },
  },
  watch: {
    preCheckIn(preCheckIn) {
      if (preCheckIn) {
        this.validatePreCheckIn(preCheckIn.status)
      }
    },
  },
  created() {
    window.addEventListener('resize', this.resizeEvent)
  },
  mounted() {
    this.resizeEvent()
  },
  beforeCreate() {
    this.$store.dispatch('getReservations')
  },
  beforeMount() {
    if (this.reservation.pre_check_in) {
      this.validatePreCheckIn(this.reservation.pre_check_in.status)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    validatePreCheckIn(status) {
      if (status !== 'Done') {
        this.$router.push({
          name: 'pre-check-in',
          params: {
            id: this.reservation.confirmation_code,
            message:
              'Pre Check-In process must be completed in order to watch reservation info',
          },
        })
      }
    },
    goBack() {
      this.$router.back()
    },
    showDirections() {
      window.open(
        this.googleMapsLink,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    resizeEvent() {
      this.carouselHeight = this.$refs.reservationInfo.clientWidth * 0.65
    },
  },
}
</script>

<style scoped>
.reservation-info-wrapper {
  /*background-color: #9898981f;*/
  position: absolute;
  height: 100%;
}
.gallery-container {
  min-height: 300px;
}
.map-wrapper {
  position: relative;
}
.back-button {
  position: absolute;
  left: 10px;
}
.border-img {
  border: 3px solid white;
}
</style>
